import { createContext, useContext, useEffect, useState } from "react";
import { useInstallationsQuery } from "../../queries/data-analysis.query";
import { CenteredLoader } from "./CenteredLoader";

export interface InstallationContextProviderProps {
  children: React.ReactNode;
}

export interface InstallationContextProps {
  hasInstallation: boolean;
  selectedInstallationId: number;
  setSelectedInstallationId: (v: number) => void;
}

const InstallationContext = createContext<InstallationContextProps>({
  hasInstallation: false,
  selectedInstallationId: 0,
  setSelectedInstallationId: (v) => {},
});

export function InstallationContextProvider({
  children,
}: InstallationContextProviderProps) {
  const installationsQuery = useInstallationsQuery();
  const [selectedInstallationId, setSelectedInstallationId] =
    useState<number>(0);
  const [hasInstallation, setHasInstallation] = useState<boolean>(false);

  useEffect(() => {
    if (installationsQuery.data) {
      if (installationsQuery.data.length > 0) {
        if (
          !selectedInstallationId ||
          !installationsQuery.data.find((d) => d.id === selectedInstallationId)
        ) {
          setSelectedInstallationId(installationsQuery.data[0].id);
          setHasInstallation(true);
        }
      } else {
        setSelectedInstallationId(0);
        setHasInstallation(false);
      }
    }
  }, [
    installationsQuery.data,
    selectedInstallationId,
    setSelectedInstallationId,
  ]);

  if (!installationsQuery.data && installationsQuery.isLoading) {
    return <CenteredLoader />;
  }

  if (!installationsQuery.data) return <></>;

  return (
    <InstallationContext.Provider
      value={{
        selectedInstallationId,
        setSelectedInstallationId,
        hasInstallation,
      }}
    >
      {children}
    </InstallationContext.Provider>
  );
}

export function useInstallationSelector() {
  return useContext(InstallationContext);
}
